<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle
                      as="h3"
                      class="text-lg font-medium leading-6 text-gray-900"
                      >Upload or choose an image</DialogTitle
                    >
                    <div class="flex flex-wrap gap-2">
                      <div
                        v-for="image in images"
                        :key="image.id"
                        class="w-1/4"
                      >
                        <img
                          :src="image.src"
                          class="outline m-2"
                          @click="
                            () => {
                              if (!currentElement) {
                                createElementImage(image.id);
                              } else {
                                updateElementImage(currentElement, image.id);
                                currentElement = null;
                              }
                              open = false;
                            }
                          "
                        />
                      </div>
                    </div>
                    <div class="mt-2 w-[445px] h-[100px]">
                      <DropZone
                        :maxFiles="Number(10000000000)"
                        :url="getDropzoneURL()"
                        :uploadOnDrop="true"
                        :multipleUpload="true"
                        :parallelUpload="3"
                        @uploaded="refreshImages()"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <button
                  type="button"
                  class="inline-flex w-full justify-center rounded-md border border-transparent bg-emerald-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="
                    open = false;
                    currentElement = null;
                  "
                >
                  Done
                </button>
                <!-- <button
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="open = false"
                  ref="cancelButtonRef"
                >
                  Cancel
                </button> -->
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <div class="min-h-screen flex flex-col">
    <header class="bg-white shadow p-4 z-50">
      <div class="flex justify-between">
        <div class="flex items-center">
          <div>
            <img
              src="https://portal.vividtech.eu/images/logo_vt_1x1.png"
              class="w-7"
            />
          </div>
          <div>
            <h3 class="font-medium">Layout builder v3.0</h3>
          </div>
          <div>
            <h4
              class="ml-2 pl-2 border-slate-300 border-l font-medium text-sm text-slate-500"
            >
              Test layout
            </h4>
          </div>
        </div>
        <div>
          <button
            @click="saveLayout"
            class="ml-1 inline-flex justify-center rounded border border-transparent bg-emerald-500 py-1.5 px-3 pl-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                fill-rule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clip-rule="evenodd"
              />
            </svg>
            Save
          </button>

          <button
            type="submit"
            class="ml-1 inline-flex justify-center rounded border border-transparent bg-indigo-500 py-1.5 px-3 pl-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z"
              />
            </svg>
            Save&Publish
          </button>

          <button
            type="submit"
            class="ml-1 inline-flex justify-center rounded border border-gray-200 bg-gray-50 py-1.5 px-3 pl-2 text-sm font-medium text-slate-500 shadow-sm hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                fill-rule="evenodd"
                d="M6.28 5.22a.75.75 0 010 1.06L2.56 10l3.72 3.72a.75.75 0 01-1.06 1.06L.97 10.53a.75.75 0 010-1.06l4.25-4.25a.75.75 0 011.06 0zm7.44 0a.75.75 0 011.06 0l4.25 4.25a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06-1.06L17.44 10l-3.72-3.72a.75.75 0 010-1.06zM11.377 2.011a.75.75 0 01.612.867l-2.5 14.5a.75.75 0 01-1.478-.255l2.5-14.5a.75.75 0 01.866-.612z"
                clip-rule="evenodd"
              />
            </svg>
            XML editor
          </button>

          <button
            type="submit"
            class="ml-1 inline-flex justify-center rounded border border-gray-200 bg-gray-50 py-1.5 px-2 text-sm font-medium text-slate-500 shadow-sm hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
              />
            </svg>
          </button>
        </div>
      </div>
    </header>

    <main class="h-64 flex flex-grow">
      <div
        class="basis-2/12 border-r border-gray-200 p-4 relative"
        id="__container_scenes"
      >
        <div class="flex justify-between mb-4">
          <h3>
            Scenes
            <span
              class="text-xs text-emerald-400 font-medium"
              v-text="Object.keys(layout.scenes).length"
              v-if="layout?.scenes"
            ></span>
          </h3>
          <button
            @click="createNewScene()"
            type="submit"
            class="inline-flex justify-center rounded border border-transparent bg-amber-500 py-1 px-2 pl-1 text-sm font-normal text-white shadow-sm hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-400 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
              />
            </svg>
            Add scene
          </button>
        </div>
        <div v-if="layout?.scenes">
          <div v-for="(scene, key, index) in layout.scenes" :key="index">
            <div class="flex justify-between">
              <h3
                class="text-sm font-medium text-slate-400"
                v-text="index + 1 + '. ' + scene.__attributes.name"
              ></h3>
              <div class="leading-none">
                <span
                  class="inline-flex text-xs bg-amber-500 text-white py-0 px-1 rounded leading-normal"
                  v-show="
                    parseInt(scene.__attributes.id) ==
                    parseInt(layout.layout.defaultScene)
                  "
                  >Default scene</span
                >
              </div>
            </div>

            <div
              class="mb-4 max-w-sm border-gray-200 rounded border text-white shadow cursor-pointer hover:ring-2 ring-gray-500 group/item relative"
              style="background-image: url('/images/bg_checkered.png')"
            >
              <img
                src=""
                v-bind:id="'scene_preview-' + key"
                @click.self="setActiveScene(scene)"
              />

              <div
                class="flex flex-row group/edit invisible bg-slate-200 group-hover/item:visible absolute bottom-0 left-0 right-0 text-gray-700"
              >
                <a
                  class="basis-1/3 block text-xs p-1 hover:text-amber-500"
                  @click="setDefaultScene(scene)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 inline-block"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                  </svg>
                  Default
                </a>
                <a
                  class="basis-1/3 block text-xs border-x hover:text-amber-500 border-gray-800 p-1"
                  @click="duplicateScene(scene)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 inline-block"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                    />
                  </svg>
                  Duplicate
                </a>
                <a
                  class="basis-1/3 block text-xs p-1 hover:text-amber-500"
                  @click="removeScene(scene)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 inline-block"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="basis-7/12">
        <div class="flex items-end p-4" v-if="activeScene">
          <h2
            class="text-xl hover:text-indigo-600 hover:cursor-pointer"
            v-text="activeScene.__attributes.name"
            v-show="!activeScene.__nameEditingEnabled"
            @click="toggleSceneNameEdit(true)"
          ></h2>
          <input
            type="text"
            class="text-xl py-0 px-1 rounded mt-1"
            v-model="activeScene.__attributes.name"
            v-show="activeScene.__nameEditingEnabled"
            @blur="toggleSceneNameEdit()"
            ref="sceneNameInput"
          />
          <span class="text-slate-400 pb-2 pl-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-3 h-3"
            >
              <path
                d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z"
              />
              <path
                d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z"
              />
            </svg>
          </span>
        </div>

        <div style="width: 482px" class="mx-auto mt-10">
          <div
            style="
              width: 480px;
              height: 320px;
              background-image: url('/images/bg_checkered.png');
            "
            class="border relative box-content"
            id="__builder_container"
          ></div>

          <h3 class="text-gray-500 mt-5">Scene settings</h3>

          <div class="mt-2" v-if="activeScene">
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                class="form-checkbox checked:bg-amber-500"
                :checked="activeScene.__showSlideshowControls"
                @change="toggleSlideshowControls()"
              />
              <span class="ml-2 text-sm">Enable slide show</span>
            </label>

            <div
              class="flex flex-row"
              v-show="activeScene.__showSlideshowControls"
            >
              <div class="basis-1/2 pr-2">
                <div class="text-sm">Next scene</div>
                <select
                  v-model="activeScene.__attributes.slideshow_next"
                  class="w-full text-xs py-0 px-1 rounded"
                  placeholder="---"
                  v-if="layout?.scenes"
                >
                  <option value="0">---</option>
                  <option
                    v-for="scene in layout.scenes"
                    :value="scene.__attributes.id"
                    v-text="scene.__attributes.name"
                    :key="scene.__attributes.id"
                  ></option>
                </select>
              </div>
              <div class="basis-1/2 pl-2">
                <div class="text-sm">Scene timeout, seconds</div>
                <select
                  v-model="activeScene.__attributes.slideshow_duration"
                  class="w-full text-xs py-0 px-1 rounded"
                >
                  <option
                    v-for="n in 19"
                    :value="n + 1"
                    v-text="n + 1"
                    :key="n"
                  ></option>
                </select>
              </div>
            </div>
          </div>

          <div class="mt-5" v-if="activeScene">
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                class="form-checkbox checked:bg-amber-500"
                :checked="activeScene.__showProxiControls"
                @change="toggleProxiControls()"
              />
              <span class="ml-2 text-sm">Enable proximity trigger</span>
            </label>

            <div class="flex flex-row" v-show="activeScene.__showProxiControls">
              <div class="basis-1/2 pr-2">
                <div class="text-sm">Trigger scene</div>
                <select
                  v-model="activeScene.__attributes.proxi_trigger_scene"
                  class="w-full text-xs py-0 px-1 rounded"
                  v-if="layout?.scenes"
                >
                  <option value="0">---</option>
                  <option
                    v-for="scene in layout.scenes"
                    :value="scene.__attributes.id"
                    v-text="scene.__attributes.name"
                    :key="scene.__attributes.id"
                  ></option>
                </select>
              </div>
              <div class="basis-1/2 pl-2">
                <div class="text-sm">Distance, cm</div>
                <select
                  v-model="activeScene.__attributes.proxi_trigger_distance"
                  class="w-full text-xs py-0 px-1 rounded"
                >
                  <option
                    v-for="n in 10"
                    :value="n * 100"
                    v-text="n * 10"
                    :key="n"
                  ></option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="basis-3/12 border-l border-gray-200 bg-gray-400 shadow relative pr-3"
        id="__container_elements"
      >
        <div class="flex">
          <div class="flex-auto p-1">
            <button
              class="text-xs block text-center border border-slate-600 bg-slate-300 text-slate-600 w-full rounded"
              @click="showImagesModal()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 mx-auto my-1 stroke-slate-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
              Image
            </button>
          </div>
          <div class="flex-auto p-1">
            <button
              class="text-xs block text-center border border-slate-600 bg-slate-300 text-slate-600 w-full rounded"
              @click="createElementButton()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 mx-auto my-1 stroke-slate-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                />
              </svg>
              Link
            </button>
          </div>
          <div class="flex-auto p-1" v-if="this.fonts">
            <button
              class="text-xs block text-center border border-slate-600 bg-slate-300 text-slate-600 w-full rounded"
              @click="createElementText()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 mx-auto my-1 stroke-slate-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
                />
              </svg>
              Text
            </button>
          </div>
          <div class="flex-auto p-1">
            <button
              class="text-xs block text-center border border-slate-600 bg-slate-300 text-slate-600 w-full rounded"
              @click="createElementBox()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 mx-auto my-1 stroke-slate-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z"
                />
              </svg>
              Box
            </button>
          </div>
        </div>

        <div v-if="activeScene">
          <draggable
            :list="activeScene.elements"
            item-key="id"
            @start="dragging = true"
            @end="
              dragging = false;
              this.redrawScene();
            "
            handle=".draggable-handle"
            v-bind="dragOptions"
            :component-data="{
              tag: 'ul',
              type: 'transition-group',
              name: !drag ? 'flip-list' : null,
            }"
          >
            <template #item="{ element }">
              <div class="list-group-item">
                <h3
                  class="flex text-white text-xs px-1 py-1 border-b border-gray-500 cursor-pointer hover:bg-indigo-500"
                  :class="{
                    'bg-indigo-600': element.__isActive,
                    'bg-gray-600': !element.__isActive,
                    'bg-amber-600': element.__isManipulating,
                  }"
                  @click="element.__isActive = !element.__isActive"
                >
                  <div>
                    <svg
                      v-if="!element.__isActive"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <svg
                      v-if="element.__isActive"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="text-left flex-grow">
                    <span v-text="element.element + ' ' + element.id"></span>
                  </div>
                  <div>
                    <span
                      v-if="element.__isActive"
                      @click="removeElement(element)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                  </div>
                  <div>
                    <span class="draggable-handle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                        />
                      </svg>
                    </span>
                  </div>
                </h3>
                <div class="p-1 element-container" v-show="element.__isActive">
                  <div class="flex items-center mt-1">
                    <div class="w-16 text-xs text-gray-100">Top</div>
                    <div class="flex-grow pr-3">
                      <input
                        type="number"
                        min="0"
                        class="w-full text-xs py-0 px-1 rounded"
                        v-model="element.top"
                        v-on:change="redrawScene"
                      />
                    </div>
                    <div class="w-16 text-xs text-gray-100">Left</div>
                    <div class="flex-grow">
                      <input
                        type="number"
                        min="0"
                        class="w-full text-xs py-0 px-1 rounded"
                        v-model="element.left"
                        v-on:change="redrawScene"
                      />
                    </div>
                  </div>

                  <div v-if="elementShowSize(element)">
                    <div class="flex items-center mt-1">
                      <div class="w-16 text-xs text-gray-100">Width</div>
                      <div class="flex-grow pr-3">
                        <input
                          type="number"
                          min="1"
                          class="w-full text-xs py-0 px-1 rounded"
                          v-model="element.width"
                          v-on:change="redrawScene"
                        />
                      </div>
                      <div class="w-16 text-xs text-gray-100">Height</div>
                      <div class="flex-grow">
                        <input
                          type="number"
                          min="1"
                          class="w-full text-xs py-0 px-1 rounded"
                          v-model="element.height"
                          v-on:change="redrawScene"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-if="elementShowDimgKey(element)">
                    <div class="flex items-center mt-1">
                      <div class="w-16 text-xs text-gray-100">Key</div>
                      <div class="flex-grow">
                        <input
                          type="text"
                          class="w-full text-xs py-0 px-1 rounded"
                          v-model="element.key"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-if="elementShowImage(element)">
                    <div class="flex items-center mt-1">
                      <div class="w-16 text-xs text-gray-100">Image</div>
                      <div class="flex-grow p-4">
                        <img
                          :src="getImageSource(element.id)"
                          @click="
                            open = true;
                            this.currentElement = element;
                          "
                        />
                      </div>
                    </div>
                  </div>

                  <div v-if="elementShowColorPicker(element)">
                    <div class="flex items-center mt-1">
                      <div class="w-16 text-xs text-gray-100">Color</div>
                      <div class="flex-grow">
                        <input
                          type="text"
                          class="w-full text-xs py-0 px-1 rounded"
                          v-model="element.color"
                          data-coloris
                          v-on:change="redrawScene"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-if="elementShowFontOptions(element)">
                    <div class="flex items-center mt-1">
                      <div class="w-16 text-xs text-gray-100">Font name</div>
                      <div class="flex-grow">
                        <select
                          v-model="element.font"
                          class="w-full text-xs py-0 px-1 rounded"
                          v-on:change="redrawScene"
                        >
                          <option
                            v-for="font in fonts"
                            :value="font"
                            v-text="font.name"
                            :key="font.name"
                          ></option>
                        </select>
                      </div>
                    </div>

                    <div class="flex items-center mt-1" v-if="element.font">
                      <div class="w-16 text-xs text-gray-100">Font size</div>
                      <div class="flex-grow">
                        <select
                          v-model="element.font_size"
                          class="w-full text-xs py-0 px-1 rounded"
                          v-on:change="redrawScene"
                        >
                          <option
                            v-for="fontSize in element.font?.size"
                            :value="fontSize"
                            v-text="fontSize.size"
                            :key="fontSize.id"
                          ></option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div v-if="elementShowText(element)">
                    <div class="flex items-center mt-1">
                      <div class="w-16 text-xs text-gray-100">Text</div>
                      <div class="flex-grow">
                        <input
                          type="text"
                          class="w-full text-xs py-0 px-1 rounded"
                          v-model="element.value"
                          v-on:input="redrawScene"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-if="elementShowLink(element)">
                    <div class="flex items-center">
                      <div class="w-16 text-xs text-gray-100">Link</div>
                      <div class="flex-grow">
                        <select
                          v-model="element.sceneID"
                          class="w-full text-xs py-0 px-1 rounded"
                          v-if="layout?.scenes"
                        >
                          <option
                            v-for="scene in layout.scenes"
                            :value="scene.__attributes.id"
                            v-text="scene.__attributes.name"
                            :key="scene.__attributes.id"
                          ></option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </div>
    </main>

    <footer
      class="bg-white border-t py-3 px-4 font-medium text-xs text-slate-500"
    >
      2023 © VIVID TECH
    </footer>
  </div>
</template>
<script>
// import { layoutArray, imageArray } from "./data";
import { getDistance, getClientRect, getTotalBox } from "./calculate_helpers";

import { nextTick, ref } from "vue";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import Konva from "konva";

import "@melloware/coloris/dist/coloris.css";
import Coloris from "@melloware/coloris";

import draggable from "vuedraggable";

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

const konva_container = "__builder_container";
let screenWidth = 480;
let screenHeight = 320;
const previewScale = 1;

var stage, layer, tr, addResizeTransformer, addMoveTransformer;

import { DropZone } from "dropzone-vue";

export default {
  components: {
    draggable,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    DropZone,
  },
  data() {
    return {
      showcolorpicker: false,
      color: "#59c7f9",
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      drag: false,
      maxSceneId: 0,
      activeScene: null,
      layout: null,
      images: null,
      fonts: null,
      ps_scenes: null,
      ps_elements: null,
      open: ref(false),
      currentElement: null,
      token: null,
    };
  },

  methods: {
    async saveLayout() {
      const layout = new URLSearchParams(window.location.search).get("layout");

      const request = await fetch(process.env.VUE_APP_LAYOUT_SAVE + layout, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
        data: JSON.stringify(this.layout),
      });

      await request.json();
    },

    getDropzoneURL() {
      const layout = new URLSearchParams(window.location.search).get("layout");

      return process.env.VUE_APP_UPLOAD + layout;
    },

    async refreshImages() {
      const layout = new URLSearchParams(window.location.search).get("layout");

      const request = await fetch(process.env.VUE_APP_UPLOADS_GET + layout, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      });

      const data = await request.json();
      this.images = data;
      return data;
    },

    colorValues(color) {
      if (!color) return;
      if (color.toLowerCase() === "transparent") return [0, 0, 0, 0];
      if (color[0] === "#") {
        if (color.length < 7) {
          // convert #RGB and #RGBA to #RRGGBB and #RRGGBBAA
          color =
            "#" +
            color[1] +
            color[1] +
            color[2] +
            color[2] +
            color[3] +
            color[3] +
            (color.length > 4 ? color[4] + color[4] : "");
        }
        return `rgba(${parseInt(color.substr(1, 2), 16)},${parseInt(
          color.substr(3, 2),
          16
        )},${parseInt(color.substr(5, 2), 16)},${
          color.length > 7 ? parseInt(color.substr(7, 2), 16) / 255 : 1
        })`;
      }
    },

    openSucker(isOpen) {
      if (isOpen) {
        // ... canvas be created
        // this.suckerCanvas = canvas
        // this.suckerArea = [x1, y1, x2, y2]
      } else {
        // this.suckerCanvas && this.suckerCanvas.remove
      }
    },

    getImageSource(id) {
      return this.images[id].src;
    },

    removeScene(scene) {
      if (Object.keys(this.layout.scenes).length == 1) {
        alert("Can`t delete last scene from layout!");
        return;
      }
      if (this.layout.layout.defaultScene == scene.__attributes.id) {
        alert("Can`t delete default scene!");
        return;
      }

      delete this.layout.scenes[scene.__attributes.id];

      this.setActiveScene(
        this.layout.scenes[this.layout.layout.defaultScene],
        true
      );
    },
    removeElement(element) {
      var indexToRemove = this.activeScene.elements.indexOf(element);
      this.activeScene.elements.splice(indexToRemove, 1);
      this.renderScene(this.activeScene, true);
    },
    setDefaultScene(scene) {
      this.layout.layout.defaultScene = scene.__attributes.id;
    },
    duplicateScene(scene) {
      var newSceneId = this.maxSceneId + 1;
      this.layout.scenes[newSceneId] = {
        __attributes: {
          id: newSceneId,
          name: "Copy of " + scene.__attributes.name,
        },
        elements: JSON.parse(JSON.stringify(scene.elements)),
      };
      this.maxSceneId = newSceneId;
      this.setActiveScene(this.layout.scenes[newSceneId], true, true);
      this.ps_scenes.update();
    },
    toggleSceneNameEdit(focus) {
      this.activeScene.__nameEditingEnabled =
        !this.activeScene.__nameEditingEnabled;

      if (focus) {
        setTimeout(() => {
          this.$refs.sceneNameInput.focus();
        }, 0);
      }
    },
    toggleProxiControls() {
      this.activeScene.__showProxiControls =
        !this.activeScene.__showProxiControls;
      if (!this.activeScene.__showProxiControls) {
        this.activeScene.__attributes.proxi_trigger_scene = 0;
      }
    },
    toggleSlideshowControls() {
      this.activeScene.__showSlideshowControls =
        !this.activeScene.__showSlideshowControls;
      if (!this.activeScene.__showSlideshowControls) {
        this.activeScene.__attributes.slideshow_next = 0;
      }
    },
    toggleElementControls(element) {
      element.__isActive = !element.__isActive;
    },
    showElementControls(element) {
      element.__isActive = true;
    },
    setElementManipulating(element) {
      this.activeScene.elements.forEach((element) => {
        element.__isManipulating = false;
      });
      element.__isManipulating = true;
    },
    redrawScene() {
      this.renderScene(this.activeScene);
    },
    renderScene(scene, delayPreviewRender = false) {
      scene.__showProxiControls =
        this.activeScene.__attributes.proxi_trigger_scene != 0;
      scene.__showSlideshowControls =
        this.activeScene.__attributes.slideshow_next != 0;

      stage = new Konva.Stage({
        container: konva_container,
        width: screenWidth,
        height: screenHeight,
      });
      layer = new Konva.Layer();
      stage.add(layer);
      this.reversedElementArray(scene).forEach((element) => {
        addResizeTransformer = false;
        addMoveTransformer = false;
        if (typeof element.__isActive === "undefined")
          element.__isActive = false;

        var konvaShape;
        if (element.element == "box") {
          konvaShape = this.drawRect(element);
          addResizeTransformer = true;
        } else if (element.element == "image") {
          konvaShape = this.drawImage(element);
          addMoveTransformer = true;
        } else if (element.element == "textline") {
          konvaShape = this.drawText(element);
          addResizeTransformer = true;
        } else if (element.element == "button") {
          konvaShape = this.drawButton(element);
          addResizeTransformer = true;
        } else {
          return;
        }

        layer.add(konvaShape);

        // var tooltip = new Konva.Text({
        //   text: "",
        //   fontFamily: "Arial",
        //   fontSize: 16,
        //   padding: 5,
        //   fill: "white",
        //   alpha: 1,
        //   visible: false,
        //   shadowEnabled: true,
        //   shadowColor: "black",
        //   shadowBlur: 1,
        //   shadowOffset: { x: 1, y: 1 },
        // });

        // layer.add(tooltip);

        konvaShape.on("click", () => {
          this.showElementControls(element);
          this.setElementManipulating(element);
          //var mousePos = stage.getPointerPosition();
          // tooltip.position({
          //   x: mousePos.x - 20,
          //   y: mousePos.y - 20,
          // });
          // tooltip.text(element.element + " " + element.id);
          // tooltip.show();
          // setTimeout(() => {
          //   tooltip.hide();
          // }, 1500);
        });

        tr = null;

        if (addMoveTransformer) {
          tr = new Konva.Transformer({
            anchorStroke: "red",
            anchorFill: "yellow",
            anchorSize: 5,
            borderStroke: "green",
            borderDash: [1, 1],
            keepRatio: false,
            rotateEnabled: false,
            resizeEnabled: false,
            boundBoxFunc: (oldBox, newBox) => {
              var box = getClientRect(newBox);
              var isOut =
                box.x < 0 ||
                box.y < 0 ||
                box.x + box.width > stage.width() ||
                box.y + box.height > stage.height();

              // if new bounding box is out of visible viewport, let's just skip transforming
              // this logic can be improved by still allow some transforming if we have small available space
              if (isOut) {
                return oldBox;
              }
              return newBox;
            },
          });
        }
        if (addResizeTransformer) {
          tr = new Konva.Transformer({
            anchorStroke: "red",
            anchorFill: "yellow",
            anchorSize: 5,
            borderStroke: "green",
            borderDash: [1, 1],
            keepRatio: false,
            rotateEnabled: false,
            boundBoxFunc: (oldBox, newBox) => {
              var box = getClientRect(newBox);
              var isOut =
                box.x < 0 ||
                box.y < 0 ||
                box.x + box.width > stage.width() ||
                box.y + box.height > stage.height();
              // if new bounding box is out of visible viewport, let's just skip transforming
              // this logic can be improved by still allow some transforming if we have small available space
              if (isOut) {
                return oldBox;
              }
              return newBox;
            },
          });

          layer.add(tr);

          stage.on("mousemove", () => {
            if (!tr.nodes().length) {
              return;
            }
            if (tr.isTransforming()) {
              return;
            }

            var hoverRect = tr.nodes()[0].getClientRect();
            if (hoverRect) {
              var dist = getDistance(hoverRect, stage.getPointerPosition());
              if (dist > 15) {
                tr.nodes([]);
              }
            }
          });

          konvaShape.on("mouseenter", () => {
            if (tr && tr.isTransforming()) {
              return;
            }
            if (tr) {
              tr.nodes([konvaShape]);
            }
          });

          konvaShape.on("mouseout", () => {
            //tooltip.hide();
          });

          konvaShape.on("mouseover", () => {
            // if (tr) {
            //   tr.nodes([konvaShape]);
            // }
          });

          konvaShape.on("transform", () => {
            this.setElementManipulating(element);
            this.showElementControls(element);

            var newWidth = parseInt(konvaShape.scaleX() * konvaShape.width());
            var newHeight = parseInt(konvaShape.scaleY() * konvaShape.height());
            element.left = parseInt(konvaShape.x());
            element.top = parseInt(konvaShape.y());
            element.width = newWidth;
            element.height = newHeight;
          });

          konvaShape.on("transformend", () => {
            //tr.detach();
            this.renderPreview(scene, delayPreviewRender, stage);
          });
          konvaShape.on("mouseup", () => {
            //tooltip.hide();
          });
        }

        konvaShape.on("dragmove", () => {
          this.showElementControls(element);
          this.setElementManipulating(element);

          element.left = parseInt(konvaShape.x());
          element.top = parseInt(konvaShape.y());

          var boxes = tr?.nodes().map((node) => node.getClientRect());
          var box = getTotalBox(boxes);
          tr?.nodes().forEach((shape) => {
            var absPos = shape.getAbsolutePosition();
            // where are shapes inside bounding box of all shapes?
            var offsetX = box.x - absPos.x;
            var offsetY = box.y - absPos.y;

            // we total box goes outside of viewport, we need to move absolute position of shape
            var newAbsPos = { ...absPos };
            if (box.x < 0) {
              newAbsPos.x = -offsetX;
            }
            if (box.y < 0) {
              newAbsPos.y = -offsetY;
            }
            if (box.x + box.width > stage.width()) {
              newAbsPos.x = stage.width() - box.width - offsetX;
            }
            if (box.y + box.height > stage.height()) {
              newAbsPos.y = stage.height() - box.height - offsetY;
            }
            shape.setAbsolutePosition(newAbsPos);
            element.left = parseInt(newAbsPos.x);
            element.top = parseInt(newAbsPos.y);
          });
        });
      });

      stage.on("dragend", () => {
        // tr.detach();
        this.renderPreview(scene, delayPreviewRender, stage);
      });

      //this.renderPreview(scene, delayPreviewRender, stage);
      return stage;
    },

    drawRect(element) {
      var rect = new Konva.Rect({
        x: parseInt(element.left),
        y: parseInt(element.top),
        width: parseInt(element.width),
        height: parseInt(element.height),
        fill: this.colorValues(element.color),
        strokeWidth: 0,
        draggable: true,
      });

      return rect;
    },
    drawButton(element) {
      var rect = new Konva.Rect({
        x: parseInt(element.left),
        y: parseInt(element.top),
        width: parseInt(element.width),
        height: parseInt(element.height),
        fill: "#9333EA55",
        stroke: "#9333EA",
        strokeScaleEnabled: false,
        strokeWidth: 1,
        draggable: true,
      });
      return rect;
    },
    drawText(element) {
      var textNode = new Konva.Text({
        text: element.value,
        x: parseInt(element.left),
        y: parseInt(element.top),
        fill: this.colorValues(element.color),
        fontSize: element.font_size?.size,
        fontFamily: element.font?.family,
        //font: element.font,
        draggable: true,
        width: parseInt(element.width),
        height: parseInt(element.height),
      });

      textNode.on("transform", function () {
        // reset scale, so only with is changing by transformer
        textNode.setAttrs({
          width: textNode.width() * textNode.scaleX(),
          height: textNode.height() * textNode.scaleY(),
          scaleX: 1,
        });
      });

      return textNode;
    },
    drawImage(element) {
      if (this.images[element.id] == undefined) {
        return false;
      }
      var imageObj = new Image();
      imageObj.crossOrigin = "anonymous";
      var image = new Konva.Image({
        x: parseInt(element.left),
        y: parseInt(element.top),
        image: imageObj,
        width: parseInt(this.images[element.id].width),
        height: parseInt(this.images[element.id].height),
        draggable: true,
      });
      imageObj.src = this.images[element.id].src;
      return image;
    },
    setActiveScene(scene, render = true, delayPreviewRender = false) {
      this.activeScene = scene;
      this.activeScene.__nameEditingEnabled = false;
      if (render) {
        this.renderScene(this.activeScene, delayPreviewRender);
      }
      setTimeout(() => this.renderPreview(scene, true, stage), 500);
    },
    createNewScene() {
      var newSceneId = this.maxSceneId + 1;
      this.layout.scenes[newSceneId] = {
        __attributes: {
          id: newSceneId,
          name: "New scene",
          slideshow_next: false,
          proxi_trigger_scene: false,
          slideshow_duration: 10,
          proxi_trigger_distance: "600",
        },
        elements: [],
      };
      this.maxSceneId = newSceneId;
      this.setActiveScene(this.layout.scenes[newSceneId], true, true);
      this.ps_scenes.update();
    },
    async showImagesModal() {
      await this.refreshImages();
      this.open = ref(true);
    },

    createElementImage(id) {
      var defaultElement = {
        element: "image",
        //id: this.activeScene.elements.length,
        id: id,
        top: 0,
        left: 0,
      };
      this.activeScene.elements.unshift(defaultElement);
      this.renderScene(this.activeScene, true);
      this.ps_elements.update();
    },

    updateElementImage(element, id) {
      const selectedElement = this.activeScene.elements.find(
        (e) => e.id === element.id
      );
      selectedElement.id = id;
      this.renderScene(this.activeScene, true);
    },

    createElementBox() {
      var defaultElement = {
        element: "box",
        id: this.activeScene.elements.length,
        width: 50,
        height: 50,
        top: 0,
        left: 0,
        color: "#000000FF",
      };
      this.activeScene.elements.unshift(defaultElement);
      this.renderScene(this.activeScene, true);
      this.ps_elements.update();
      setTimeout(() => {
        Coloris.coloris({
          el: "[data-coloris]",
          forceAlpha: true,
          defaultColor: "#000000FF",
        });
      }, 0);
    },
    createElementButton() {
      var defaultElement = {
        element: "button",
        id: this.activeScene.elements.length,
        width: 50,
        height: 50,
        top: 0,
        left: 0,
        color: "#9333EA55",
        sceneID: null,
      };
      this.activeScene.elements.unshift(defaultElement);
      this.renderScene(this.activeScene, true);
      this.ps_elements.update();
    },
    createElementText() {
      var defaultElement = {
        element: "textline",
        id: this.activeScene.elements.length,
        width: 200,
        height: 50,
        top: 0,
        left: 0,
        color: "#000000FF",
        value: "Sometext",
        font: this.fonts[0],
        font_size: this.fonts[0].size[0],
      };
      this.activeScene.elements.unshift(defaultElement);
      this.renderScene(this.activeScene, true);
      this.ps_elements.update();
      setTimeout(() => {
        Coloris.coloris({
          defaultColor: "#FFFFFFFF",
          el: "[data-coloris]",
          forceAlpha: true,
        });
      }, 0);
    },
    elementShowLink(element) {
      var search = ["button"];
      return search.includes(element.element);
    },
    elementShowSize(element) {
      var search = ["textline", "button", "box"];
      return search.includes(element.element);
    },
    elementShowDimgKey(element) {
      var search = ["dimage"];
      return search.includes(element.element);
    },
    elementShowImage(element) {
      var search = ["image"];
      return search.includes(element.element);
    },
    elementShowColorPicker(element) {
      var search = ["textline", "box"];
      return search.includes(element.element);
    },
    elementShowText(element) {
      var search = ["textline"];
      return search.includes(element.element);
    },
    elementShowFontOptions(element) {
      var search = ["textline"];
      return search.includes(element.element);
    },
    updateMaxSceneId() {
      Object.keys(this.layout.scenes).forEach((key) => {
        if (parseInt(key) > this.maxSceneId) this.maxSceneId = parseInt(key);
      });
    },
    async renderPreview(scene, waitForTick = false, stageData) {
      if (waitForTick) {
        await nextTick();
      }
      var imgData = stageData
        ? stageData.toDataURL({ pixelRatio: previewScale })
        : stage.toDataURL({ pixelRatio: previewScale });
      document.getElementById("scene_preview-" + scene.__attributes.id).src =
        imgData;
    },

    reversedElementArray(scene) {
      var reversedArray = scene.elements.slice().reverse();
      return reversedArray;
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  async created() {
    // check for auth
    const auth = new URLSearchParams(window.location.search).get("auth");
    const layout = new URLSearchParams(window.location.search).get("layout");

    if (localStorage.getItem("token")) {
      this.token = localStorage.getItem("token");
    } else if (!localStorage.getItem("token") && auth) {
      const request = await fetch(process.env.VUE_APP_AUTH, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(auth),
      });
      const data = await request.json();
      this.token = data.token;
      localStorage.setItem("token", data.token);
    } else {
      window.location = "https://portal.vividtech.eu";
    }

    // kaut kas neiet - nonstopā rausta servisu

    setInterval(async () => {
      const request = await fetch(process.env.VUE_APP_AUTH, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.token),
      });
      const data = await request.json();
      if (data) {
        console.log("Token check successful");
      } else {
        console.log("Token check failed");
        confirm("Session expired");
        window.location = "https://portal.vividtech.eu";
      }
    }, 1000 * 60);

    // get layout

    const request = await fetch(process.env.VUE_APP_LAYOUT_GET + layout, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    });
    const data = await request.json();
    this.layout = data.layout;
    this.images = data.images;
    this.fonts = data.fonts;
    let imagesLoaded = 0;
    this.setActiveScene(
      this.layout.scenes[this.layout.layout.defaultScene],
      false
    );
    this.updateMaxSceneId();
    Object.keys(this.images).forEach((img) => {
      const image = new Image();
      image.addEventListener("load", () => {
        imagesLoaded++;
        if (imagesLoaded === Object.keys(this.images).length) {
          // render all scenes
          Object.keys(this.layout.scenes).forEach((key) => {
            //this.setActiveScene(this.layout.scenes[key]);
            let stage = this.renderScene(this.layout.scenes[key]);
            setTimeout(
              () => this.renderPreview(this.layout.scenes[key], false, stage),
              1000
            );
          });
          this.renderScene(this.activeScene);
        }
      });
      image.crossOrigin = "anonymous";
      image.src = this.images[img].src;
    });
  },
  mounted() {
    this.open = ref(false);

    Coloris.init({
      el: "[data-coloris]",
      forceAlpha: true,
    });

    this.ps_scenes = new PerfectScrollbar("#__container_scenes", {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20,
    });

    this.ps_elements = new PerfectScrollbar("#__container_elements", {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20,
    });
  },
};
</script>

<style>
.hu-color-picker {
  padding: 10px;
  background: #1d2024;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  z-index: 1;
}
.hu-color-picker.light {
  background: #f7f8f9;
}
.hu-color-picker.light .color-show .sucker {
  background: #eceef0;
}
.hu-color-picker.light .color-type .name {
  background: #e7e8e9;
}
.hu-color-picker.light .color-type .value {
  color: #666;
  background: #eceef0;
}
.hu-color-picker.light .colors.history {
  border-top: 1px solid #eee;
}
.hu-color-picker canvas {
  vertical-align: top;
}
.hu-color-picker .color-set {
  display: flex;
}
.hu-color-picker .color-show {
  margin-top: 8px;
  display: flex;
}
.saturation {
  position: relative;
  cursor: pointer;
}
.saturation .slide {
  position: absolute;
  left: 100px;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.color-alpha {
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}
.color-alpha .slide {
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  height: 4px;
  background: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.sucker {
  width: 30px;
  fill: #9099a4;
  background: #2e333a;
  cursor: pointer;
  transition: all 0.3s;
}
.sucker.active,
.sucker:hover {
  fill: #1593ff;
}
.hue {
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}
.hue .slide {
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  height: 4px;
  background: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.colors {
  padding: 0;
  margin: 0;
}
.colors.history {
  margin-top: 10px;
  border-top: 1px solid #2e333a;
}
.colors .item {
  position: relative;
  width: 16px;
  height: 16px;
  margin: 10px 0 0 10px;
  border-radius: 3px;
  box-sizing: border-box;
  vertical-align: top;
  display: inline-block;
  transition: all 0.1s;
  cursor: pointer;
}
.colors .item:nth-child(8n + 1) {
  margin-left: 0;
}
.colors .item:hover {
  transform: scale(1.4);
}
.colors .item .alpha {
  height: 100%;
  border-radius: 4px;
}
.colors .item .color {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.color-type {
  display: flex;
  margin-top: 8px;
  font-size: 12px;
}
.color-type .name {
  width: 60px;
  height: 30px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  background: #252930;
}
.color-type .value {
  flex: 1;
  height: 30px;
  min-width: 100px;
  padding: 0 12px;
  border: 0;
  color: #fff;
  background: #2e333a;
  box-sizing: border-box;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}

.dropzone__box {
  height: 100%;
  border: 2px dashed #4f49e2 !important;
}
</style>
